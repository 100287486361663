import React, { useState, useEffect } from "react";
import "./PricingComponentStyles.css"; // Assuming you have your CSS in a separate file
import icon from "./Images/favicon.png";
import { HandleGetAllPlan } from "./api";
import { LinearProgress } from "@mui/material";

const PricingComponent = ({ onPriceIdChange, accessToken }) => {
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);
  const [pageviews, setPageviews] = useState("400");
  const [price, setPrice] = useState(40.0);
  const period = "month";
  const [priceId, setPriceId] = useState("");
  console.log(priceId);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await HandleGetAllPlan(accessToken);

        setPlans(data);
        const initialPlan = data.find((plan) => plan.no_of_questions === "400");
        if (initialPlan) {
          setPriceId(initialPlan.price_id);
          onPriceIdChange(initialPlan.price_id);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures it only runs once on mount

  const handleSliderChange = (event) => {
    const value = event.target.value;
    const selectedPlan = plans[value - 1];
    if (selectedPlan) {
      setPageviews(selectedPlan.no_of_questions);
      setPrice(parseFloat(selectedPlan.price));
      setPriceId(selectedPlan.price_id);
      onPriceIdChange(selectedPlan.price_id);
    }
  };

  return (
    <div className="pricing-container">
      {loading && <LinearProgress color="primary" />}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <img
            style={{
              margin: "0 0 15px 30px",
              borderRadius: "50px",
              height: "50px",
            }}
            src={icon}
            alt=""
          />
        </div>
        <div className="title">
          <h3>Question based pricing</h3>
        </div>
      </div>
      <div className="container">
        <div className="upper">
          <div className="left">
            <span id="pageviews">{pageviews}</span> Questions
          </div>
          <div className="right">
            <span className="price">${price.toFixed(2)}</span> /{" "}
            <span id="period">{period}</span>
          </div>
        </div>
        <div className="slider">
          <input
            type="range"
            min="1"
            max={plans.length}
            value={
              plans.findIndex((plan) => plan.no_of_questions === pageviews) + 1
            }
            id="slider"
            onChange={handleSliderChange}
          />
        </div>
        <div className="toggle-container">
          Monthly Billing
          {/* Toggle and discount information can be added here */}
        </div>
      </div>
    </div>
  );
};

export default PricingComponent;
