import React, { useState, useEffect } from "react";
import { PDFDocument, rgb } from 'pdf-lib';
import Fuse from 'fuse.js';  // Import Fuse.js
import {
  Container,
  Typography,
  Box,
  Button,
  Menu,
  MenuItem,
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  Chip,
  IconButton,
  ListItemIcon,
  Grid,
  LinearProgress,
  Card,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import FormComponent from "./FormComponent";
import { blue } from "@mui/material/colors";
import { Amplify } from "aws-amplify";
import { useTheme, View, Image, Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./custom-style.css"; // Import your custom styles
import logo from "./Images/AskGuruLogo.png";
import PricingComponent from "./PricingComponent";
import {
  HandleGetUserDetails,
  HandleReceiveAnswer,
  HandleManagePlan,
  HandleUpgrade,
  HandleGetAttachment,
} from "./api";
import Logout from "@mui/icons-material/Logout";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

import awsExports from "./Configuration";
Amplify.configure(awsExports);

const formFields = {
  signUp: {
    email: {
      order: 1,
      label: `Email*`,
      placeholder: "Enter your email address",
      isRequired: true,
    },
    password: {
      label: "Password*",
      placeholder: "Enter your password",
      isRequired: true,
      type: "password",
    },
    confirm_password: {
      label: "Confirm Password*",
      placeholder: "Confirm your password",
      isRequired: true,
      type: "password",
    },
    "custom:first_name": {
      label: "First Name",
      placeholder: "Enter your first name",
      isRequired: false,
    },
    "custom:last_name": {
      label: "Last Name",
      placeholder: "Enter your last name",
      isRequired: false,
    },
    phone_number: {
      label: "Phone Number",
      placeholder: "Enter your phone number",
      isRequired: false,
    },
  },
  signIn: {
    username: {
      label: "Email",
      placeholder: "Enter your email",
      isRequired: true,
    },
    password: {
      label: "Password",
      placeholder: "Enter your password",
      isRequired: true,
      type: "password",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  conversationContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    maxHeight: "auto",
    overflowY: "auto",
    padding: theme.spacing(2),
    borderRadius: "4px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    width: "82%",
    marginBottom: theme.spacing(4),
    alignSelf: "center",
  },
  questionResponseContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  question: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
  response: {
    textAlign: "left",
    marginBottom: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(6),
    color: blue[800],
    fontWeight: "bold",
  },
}));

const states = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export default function App() {
  const [ACCESSTOKEN, setACCESSTOKEN] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [alert, setAlert] = useState(null);
  const [priceId, setPriceId] = useState("");
  const [userId, setUserId] = useState();
  const [userEmail, setUserEmail] = useState();
  const [userDetails, setUserDetails] = useState({});
  const classes = useStyles();
  const [conversations, setConversations] = useState([]);
  const [hasConversation, setHasConversation] = useState("false");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [showFilters, setShowFilters] = useState(false);

  const handleToggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const [state, setState] = React.useState("Fedral");
  const [year, setYear] = React.useState("All");
  const [response, setResponse] = React.useState("Detailed");
  const handleChangeState = (event) => {
    setState(event.target.value);
  };
  const handleChangeYear = (event) => {
    setYear(event.target.value);
  };
  const handleChangeResponse = (event) => {
    setResponse(event.target.value);
  };

  const handleClickOpenForm = () => {
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  const handlePriceIdChange = (id) => {
    setPriceId(id);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const data = await HandleUpgrade(userId, userEmail, priceId);
      if (data) {
        window.open(data, "_blank");
      }
      setAlert(null);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setAlert(
          <Alert severity="warning">{error.response.data.detail}</Alert>
        );
      } else {
        console.error(error);
      }
    }

    handleCloseForm();
  };

  const handleManagePlaneSubmit = async () => {
    try {
      const data = await HandleManagePlan(ACCESSTOKEN);
      if (data) {
        setLoading(false);
        window.open(data, "_blank");
      }
    } catch (err) {
      setLoading(false);
      console.error(err);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const fetchAnswer = async (query, uuid, signOut) => {
    console.log("hiiii", userDetails);
    try {
      const data = await HandleReceiveAnswer(
        query,
        state,
        response,
        year,
        uuid,
        userDetails,
        ACCESSTOKEN
      );
      setAlert(null);
      setUserDetails((prevUser) => ({
        ...prevUser,
        current_query_count: data.count,
      }));
      setConversations((prevConversations) => [
        ...prevConversations,
        { question: query, response: data.data },
      ]);
      setHasConversation("true");
    } catch (error) {
      if (error.response) {
        if (error.response.status === 500) {
          setAlert(
            <Alert severity="error">{error.response.data.detail}</Alert>
          );
        } else if (error.response.status === 401) {
          signOut();
          window.location.reload();
        } else {
          console.error(error);
        }
      } else {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    if (userId) {
      setLoading(true);
      let accessTokenKey = null;

      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.endsWith(".accessToken")) {
          accessTokenKey = key;
          break;
        }
      }
      console.log('fetched token', localStorage.getItem(accessTokenKey))
      setACCESSTOKEN(localStorage.getItem(accessTokenKey));
      console.log('stored token', ACCESSTOKEN)
      fetchUserDetails(userId);
    }
  }, [userId]);

  const fetchUserDetails = async (userId) => {
    try {
      const data = await HandleGetUserDetails(userId);
      setAlert(null);
      setUserDetails(data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error(err);
      setAlert(<Alert severity="error">{err.response.data.detail}</Alert>);
    }
  };

  // window.fetchAttachment = async (pdfKey, conversationId, userQuery) => {
  //   setLoading(true);
  //   console.log("data", pdfKey, conversationId, userQuery);
  //   try {
  //     const data = await HandleGetAttachment(pdfKey, conversationId, userQuery, ACCESSTOKEN);
  //     setLoading(false);
  //     setAlert(null);
  //     window.open(data, "_blank");
  //   } catch (err) {
  //     setLoading(false);
  //     console.error(err);
  //     setAlert(<Alert severity="error">{err.response.data.detail}</Alert>);
  //   }
  // };
  
  window.fetchAttachment = async (pdfKey, conversationId, userQuery, searchQuery) => {
      setLoading(true);
      console.log("data", pdfKey, conversationId, userQuery, searchQuery);
      try {
          // Get the presigned URL from S3
          const presignedUrl = await HandleGetAttachment(pdfKey, conversationId, userQuery, ACCESSTOKEN);
          console.log(presignedUrl)
          // Fetch the actual PDF data using the presigned URL
          const response = await fetch(presignedUrl);
          if (!response.ok) {
              throw new Error('Failed to fetch the PDF from the presigned URL');
          }
          const data = await response.arrayBuffer(); // Fetch as ArrayBuffer to get binary data
          console.log("384 ", data)
          // Load the PDF document
          const pdfDoc = await PDFDocument.load(data);
          const pages = pdfDoc.getPages();
  
          let fullText = '';
          const pageTextMap = [];
  
          // Extract and join text from all pages
          for (const page of pages) {
              const textContent = await page.getTextContent();
              const pageText = textContent.items.map(item => item.str).join(' ');
              pageTextMap.push(pageText);
              fullText += pageText + ' ';
          }
  
          // Configure Fuse.js
          const fuseOptions = {
              includeMatches: true, // Return the matching characters
              threshold: 0.3, // Adjust threshold for fuzziness
          };
  
          const fuse = new Fuse([fullText], fuseOptions);
  
          // Perform fuzzy search
          const results = fuse.search(searchQuery);
          const matches = results[0]?.matches || []; // Get the matching parts
  
          matches.forEach(match => {
              let charIndex = 0;
  
              // Find the original page and position of the match
              for (let pageIndex = 0; pageIndex < pageTextMap.length; pageIndex++) {
                  const pageText = pageTextMap[pageIndex];
                  const startIndex = pageText.indexOf(match.value);
  
                  if (startIndex !== -1) {
                      const page = pages[pageIndex];
                      const textContent = page.getTextContent();
  
                      // Iterate over text items to find exact position
                      let found = false;
                      textContent.items.forEach((textItem, itemIndex) => {
                          charIndex += textItem.str.length;
  
                          if (!found && charIndex >= startIndex) {
                              found = true;
  
                              // Calculate the highlight coordinates
                              const highlight = page.drawRectangle({
                                  x: textItem.transform[4], // X coordinate
                                  y: textItem.transform[5] - 10, // Y coordinate
                                  width: textItem.width * match.value.length / textItem.str.length, // Width of the match
                                  height: 10, // Height of the highlight
                                  color: rgb(1, 1, 0), // Yellow highlight color
                              });
                          }
                      });
  
                      break; // Stop once we've found the match on the correct page
                  }
              }
          });
  
          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: "application/pdf" });
  
          const url = URL.createObjectURL(blob);
          setLoading(false);
          setAlert(null);
          window.open(url, "_blank");
      } catch (err) {
          setLoading(false);
          console.error(err);
          setAlert(<Alert severity="error">{err.message || "An error occurred"}</Alert>);
      }
  };
  

  const handleClearConversation = () => {
    setConversations([]);
    setHasConversation("false");
  };

  const Header = () => {
    const { tokens } = useTheme();
    return (
      <View style={{ textAlign: "center", padding: tokens.space.large }}>
        <Image alt="logo" src={logo} />
      </View>
    );
  };

  const components = {
    Header() {
      return <Header />;
    },
  };

  const handleButtonClick = () => {
    if (!userDetails.is_subscribed) {
      setIsSubmitted(false);
      handleClickOpenForm();
    } else {
      setLoading(true);
      handleManagePlaneSubmit();
    }
  };

  return (
    <Authenticator
      loginMechanisms={["email"]}
      formFields={formFields}
      components={components}
    >
      {({ signOut, user }) => (
        <div>
          {loading && <LinearProgress color="primary" />}
          <Container component="main" maxWidth="lg" className={classes.root}>
            <div>
              {alert}
              {setUserId(user.userId)}

              {setUserEmail(user.signInDetails.loginId)}
              <Grid
                container
                justifyContent={"center"}
                style={{
                  position: "fixed",
                  left: 0,
                  width: "100%",
                  top: 0,
                }}
              >
                <Grid item xs={12} md={10} lg={10} xl={9}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginTop: "26px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                    }}
                  >
                    <div>
                      <View
                        style={{
                          textAlign: "center",
                          width: "170px",
                          paddingTop: "10px",
                        }}
                      >
                        <Image alt="logo" src={logo} />
                      </View>
                    </div>
                    <div style={{ marginTop: "auto" }}>
                      {userDetails && Object.keys(userDetails).length > 0 && (
                        <Button
                          style={{ color: "#191970" }}
                          onClick={handleButtonClick}
                        >
                          {userDetails.is_subscribed
                            ? "Subscriptions"
                            : "Upgrade"}
                        </Button>
                      )}

                      <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Avatar sx={{ width: 32, height: 32 }}>
                          {" "}
                          {user.signInDetails.loginId.charAt(0).toUpperCase()}
                        </Avatar>
                      </IconButton>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <MenuItem onClick={handleClose}>
                  <Avatar /> {user.signInDetails.loginId}
                </MenuItem>

                <MenuItem onClick={signOut}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>

              {hasConversation === "true" && (
                <Grid
                  container
                  justifyContent={"center"}
                  style={{
                    position: "fixed",
                    left: 0,
                    width: "100%",
                    top: "10vh",
                    paddingLeft: "18px",
                    paddingRight: "18px",
                  }}
                >
                  <Grid item xs={12} md={10} lg={10} xl={9}>
                    <Box
                      sx={{
                        borderRadius: "6px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        marginX: "5px",
                        marginY: "5px",
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2px",
                        padding: "12px",
                      }}
                    >
                      <div style={{ maxHeight: "65vh", overflowY: "auto" }}>
                        {conversations.map((conversation, index) => (
                          <Box key={index}>
                            <div
                              style={{
                                border: `24px`,
                                direction: `flex`,
                                align: `left`,
                                // display: `flex`,
                                // alignItems: `end`,
                                // flexDirection: `row-reverse`
                              }}
                            >
                              <Chip
                                className={classes.question}
                                label={conversation.question}
                              />
                            </div>
                            <div
                              style={{
                                border: `24px`,
                                direction: `flex`,
                                align: `left`,
                                marginBottom: `10px`,
                              }}
                            >
                              <Typography
                                variant="body1"
                                className={classes.response}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: conversation.response,
                                  }}
                                />
                              </Typography>
                            </div>
                          </Box>
                        ))}
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {userDetails &&
                Object.keys(userDetails).length > 0 &&
                ACCESSTOKEN && (
                  <Grid
                    container
                    justifyContent={"center"}
                    style={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      height: "22vh",
                    }}
                  >
                    <Grid item xs={12} md={10} lg={10} xl={9}>
                      <Card
                        style={{
                          marginLeft: "20px",
                          marginRight: "20px",
                          marginBottom: "-10px",
                          paddingBottom: "10px",
                          marginTop: "8px",
                        }}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={10}
                            lg={10}
                            xl={9}
                            sx={{
                              display: "flex",
                              gap: 2,
                              alignItems: "center",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                            }}
                          >
                            <div
                              style={{
                                padding: "5px",
                                marginBottom: "-10px",
                                marginLeft: "-15px",
                              }}
                            >
                              <IconButton onClick={handleToggleFilters}>
                                <FilterAltOutlinedIcon
                                  style={{ color: "#191970" }}
                                />
                              </IconButton>
                            </div>

                            {showFilters && (
                              <>
                                <FormControl
                                  variant="standard"
                                  sx={{
                                    m: 1,
                                    minWidth: 100,
                                    marginBottom: "-15px",
                                  }}
                                  style={{
                                    marginBottom: "-1px",
                                    marginLeft: "-15px",
                                  }}
                                >
                                  <InputLabel id="state-id">State</InputLabel>
                                  <Select
                                    labelId="state-id"
                                    id="state-id"
                                    value={state}
                                    onChange={handleChangeState}
                                    label="State"
                                  >
                                    <MenuItem value="Fedral">
                                      <em>Fedral</em>
                                    </MenuItem>
                                    {states.map((stateName) => (
                                      <MenuItem
                                        key={stateName}
                                        value={stateName}
                                      >
                                        {stateName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormControl
                                  variant="standard"
                                  sx={{
                                    m: 1,
                                    minWidth: 90,
                                    marginBottom: "-15px",
                                  }}
                                  style={{ marginBottom: "-1px" }}
                                >
                                  <InputLabel id="response-id">
                                    Response
                                  </InputLabel>
                                  <Select
                                    labelId="response-id"
                                    id="response-id"
                                    value={response}
                                    onChange={handleChangeResponse}
                                    label="Response"
                                  >
                                    <MenuItem value="Detailed">
                                      <em>Detailed</em>
                                    </MenuItem>
                                    <MenuItem value={"Concise"}>Concise</MenuItem>
                                    <MenuItem value={"Brief"}>Brief</MenuItem>
                                    <MenuItem value={"Detailed"}>Detailed</MenuItem>
                                  </Select>
                                </FormControl>

                                <FormControl
                                  variant="standard"
                                  sx={{
                                    m: 1,
                                    minWidth: 70,
                                    marginBottom: "-15px",
                                  }}
                                  style={{ marginBottom: "-1px" }}
                                >
                                  <InputLabel id="year-id">Year</InputLabel>
                                  <Select
                                    labelId="year-id"
                                    id="year-id"
                                    value={year}
                                    onChange={handleChangeYear}
                                  >
                                    <MenuItem value="All">
                                      <em>All</em>
                                    </MenuItem>
                                    <MenuItem value="2023">2023</MenuItem>
                                    <MenuItem value="2024">2024</MenuItem>
                                  </Select>
                                </FormControl>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Card>
                      <FormComponent
                        onSendClick={(query, uuid) =>
                          fetchAnswer(query, uuid, signOut)
                        }
                        onClearClick={handleClearConversation}
                      />
                    </Grid>
                  </Grid>
                )}

              <Dialog
                open={openForm}
                onClose={handleCloseForm}
                PaperProps={{
                  component: "form",
                  onSubmit: handleSubmit,
                }}
              >
                <DialogContent>
                  <PricingComponent
                    onPriceIdChange={handlePriceIdChange}
                    accessToken={ACCESSTOKEN}
                  />
                </DialogContent>
                <DialogActions
                  style={{ marginRight: "54px", marginBottom: "30px" }}
                >
                  <Button
                    variant="outlined"
                    style={{ color: "#191970", borderColor: "#191970" }}
                    onClick={handleCloseForm}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      color: isSubmitted ? "grey" : "#191970",
                      borderColor: isSubmitted ? "grey" : "#191970",
                    }}
                    type="submit"
                    onClick={() => setIsSubmitted(true)}
                  >
                    Subscribe
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </Container>
        </div>
      )}
    </Authenticator>
  );
}
