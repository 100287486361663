const awsExports = {
    Auth: {
      Cognito: {
        userPoolClientId: "41cm0mbrokevkqqc2umrniemr0", /*staging*/
        userPoolId: "us-east-1_cizlIuiad", /*staging*/
        loginWith: {
          username: "false",
          email: "true"
        },
      },
    },
  };
  
  export default awsExports;