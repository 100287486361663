import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { TextField, Button, IconButton, CircularProgress } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import { v4 as uuidv4 } from "uuid";

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  textField: {
    marginBottom: theme.spacing(2),
    backgroundColor: "white",
  },
  sendButton: {},
}));

function FormComponent({ onSendClick, onClearClick }) {
  const classes = useStyles();
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [uuid, setUuid] = useState(uuidv4());

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!loading) {
      setLoading(true);
      try {
        await onSendClick(query, uuid);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
      setQuery("");
    }
  };

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleClear = () => {
    setQuery("");
    setUuid(uuidv4());
    onClearClick();
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        className={classes.textField}
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="message"
        label="Message"
        name="message"
        autoComplete="message"
        autoFocus
        value={query}
        onChange={handleChange}
        disabled={loading} // Disable the text field while loading
        InputProps={{
          endAdornment: (
            <IconButton
              type="submit"
              className="ml-auto text-white text-opacity-80 hover:bg-white"
              disabled={!query}
            >
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <SendIcon sx={{ color: "#191970" }} />
              )}
            </IconButton>
          ),
        }}
      />
      <div style={{ textAlign: "right" }}>
        <Button
          style={{ alignContent: "right", color: "#191970" }}
          onClick={handleClear}
        >
          Clear&nbsp;Chat
        </Button>
      </div>
    </form>
  );
}

export default FormComponent;
