import axios from "axios";

const API_END_POINT = "https://5z435ekwfnz7u45ebfreo2qfbi0xhjwh.lambda-url.us-east-1.on.aws/api/v1"
/*"http://127.0.0.1:8000/api/v1"*/
/*https://5z435ekwfnz7u45ebfreo2qfbi0xhjwh.lambda-url.us-east-1.on.aws/api/v1*/

const HandleGetUserDetails = async (userId) => {
  try {
    const url = `${API_END_POINT}/users/${userId}/subscriptions`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching user details:", error);
    throw error;
  }
};

const HandleGetAttachment = async (pdfKey, conversationId, userQuery, ACCESSTOKEN) => {
  try {
    const url = `${API_END_POINT}/access/pdfs`;
    const response = await axios.get(url, {
      params: {
        pdfkey: pdfKey,
        conversation_id: conversationId,
        user_query: userQuery,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
    });
    return response.data.url;
  } catch (error) {
    console.error("Error fetching attachment:", error);
    throw error;
  }
};

const HandleReceiveAnswer = async (query, state, answer_size, timeframe, uuid, userDetails, ACCESSTOKEN) => {
  try {
    const url = `${API_END_POINT}/query`; /*invoke-bedrock-agent*/
    const response = await axios.post(
      url,
      {
        query: query,
        state: state,
        year: timeframe,
        answer_size: answer_size,
        conversationId: uuid,
        is_subscribed: userDetails.is_subscribed,
        allowed_no_of_questions: userDetails.allowed_no_of_questions,
        subscription_expire_at: userDetails.subscription_expire_at,
        current_query_count:userDetails.current_query_count,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${ACCESSTOKEN}`,
        },
      }
    );

    return {
      data: response.data.generated_response,
      count: response.data.current_query_count,
    };

  } catch (error) {
    console.error("Error fetching answer:", error);
    throw error;
  }
};

const HandleManagePlan = async (ACCESSTOKEN) => {
  try {
    const url = `${API_END_POINT}/customers/manage-subscriptions`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
    });

    return response.data.url;
  } catch (error) {
    console.error("Error fetching subscriptions details:", error);
    throw error;
  }
};

const HandleGetAllPlan = async (ACCESSTOKEN) => {
  try {
    const url = `${API_END_POINT}/plans?get_all_plans=false`;
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${ACCESSTOKEN}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error fetching all plan details:", error);
    throw error;
  }
};

const HandleUpgrade = async (userId, userEmail, priceId) => {
  try {
    const url = `${API_END_POINT}/users/${userId}/checkout-session`;
    const response = await axios.post(
      url,
      {
        user_email: userEmail,
        price_id: priceId,
        payment_type: "recurring",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data.url;
  } catch (error) {
    console.error("Error getting upgraded:", error);
    throw error;
  }
};

export {
  HandleGetUserDetails,
  HandleReceiveAnswer,
  HandleManagePlan,
  HandleGetAllPlan,
  HandleUpgrade,
  HandleGetAttachment,
};
